import { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Grid, Card, CardContent, Typography, Button } from '@mui/material';

import AppBar from "../../Components/AppBar";
import Product from "../../Components/Cards";
import { Banner } from '../../Models/Essential/Banner';
import CarouselCards from '../../Components/Carousel/Cards';
import CarouselImagem from '../../Components/Carousel/Imagem';
import AutocompleteSelect from '../../Components/Selects/AutocompleteSelect';

import { User } from '../../Models/User';
import { City } from '../../Models/Essential/CEP';
import { MarketQuotes } from '../../Models/Quotes/Quotes';
import { Essential } from "../../Models/Essential/Essential";
import { WeatherForecast } from '../../Models/Forecast/Forecast';

import { WeatherIcon, getDevice, getWeatherColor } from '../../Services/globalFunction';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

import { list_products } from './routes';

import "../../App.css";
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const user = User.searchUser();
  const token = user.token;

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [bannerImg, setBannerImg] = useState([]);
  const [modules, setModules] = useState([]);

  const [dataCities, setDataCities] = useState([]);
  const [dataQuotes, setDataQuotes] = useState([]);
  const [selectedCity, setSelectedCity] = useState(3925);

  const [currentForecast, setCurrentForecast] = useState([]);
  const [weatherCardColor, setWeatherCardColor] = useState('#a7b7c1');

  const navigate = useNavigate();

  const validateUser = () => {
    const user = User.searchUser();

    setModules(user.modulos);
    if (user.isLoggedIn()) {
      let cidade = user.cidade_previsao === null ? parseInt(3925) : parseInt(user.cidade_previsao);
      setSelectedCity(cidade);
      setIsLoggedIn(true);
    } else if (user.modulos === null || user.modulos === '') {
      LoadModules();
    }
  };

  const LoadModules = useCallback(async () => {
    const modules = new Essential(null);
    await modules.SearchModules().then((val) => {
      localStorage.setItem('modulos', JSON.stringify(val));
      setModules(val);
    });

  }, [token]);

  const LoadCities = async () => {
    const city = new City();
    const cities = await city.SearchCities('RS');
    setDataCities(cities);
  };

  const getCurrentForecast = (forecast, today) => {
    let dataToday = forecast.datas.find((data) => data.data === today);
    if (dataToday === undefined) dataToday = forecast.datas[0];

    const currentData = dataToday.horarios[0].detalhes;
    let precipitacao = '';
    let description = '';
    let icon = '';

    if (currentData["1 Hora"] && currentData["1 Hora"].descricao) {
      description = currentData["1 Hora"].descricao;
      icon = currentData["1 Hora"].descricao_original;
      precipitacao = currentData["1 Hora"].precipitacao;
    } else {
      description = currentData["6 horas"].descricao;
      icon = currentData["6 horas"].descricao_original;
      precipitacao = currentData["6 horas"].precipitacao;
    }

    const dataForecast = {
      descricao: description,
      descricao_original: icon,
      precipitacao: precipitacao,
      umidade: Math.round(dataToday.horarios[0].umidade),
      velocidade_vento: Math.round(dataToday.horarios[0].velocidade_vento),
      temperatura_atual: Math.round(dataToday.horarios[0].temperatura),
      temperatura_min: Math.round(dataToday.temperatura_min),
      temperatura_max: Math.round(dataToday.temperatura_max),
    };

    return dataForecast;
  };

  const LoadWeatherForecast = useCallback(async () => {
    if (selectedCity) {
      const weatherForecast = new WeatherForecast();
      const forecast = await weatherForecast.SearchWeatherForecast(selectedCity);
      if (forecast.datas && forecast.datas.length > 0) {
        const today = new Date().toLocaleDateString('pt-BR');
        const TodayForecast = getCurrentForecast(forecast, today);

        setCurrentForecast(TodayForecast);
        setWeatherCardColor(getWeatherColor(TodayForecast.descricao_original || ''));
      }
    }
  }, [selectedCity]);

  const LoadQuotes = useCallback(async()=>{
    if (selectedCity) {
      const marketquotes = new MarketQuotes();
      const dataMarketQuotes = await marketquotes.SearchInitialMarketQuotes({cidade: selectedCity});
      setDataQuotes(dataMarketQuotes);
    }
  },[selectedCity])

  const LoadBanners = async () => {
    const device = getDevice();
    const Banners = new Banner();
    const banners = await Banners.SearchBanner();
    const filteredImages = banners
      .filter((banner) => banner.ativo === 'S')
      .flatMap((banner) => {
        const images = banner.imagens
          .filter((imagem) => imagem.tipo === (device === 'desktop' ? 'DESKTOP' : 'MOBILE'))
          .map((image) => ({ ...image, bannerNome: banner.nome }));

        return images;
      });

    setBannerImg(filteredImages);
  };

  const handleCityChange = async (event) => {
    if (event) {
      const user = User.searchUser();
      user.saveCity(event.id);
      setSelectedCity(event.id);
    }
  };

  const handleForecastDetails = () => {
    navigate("/previsao-tempo");
  };

  const handleQuotesDetails = () => {
    navigate("/cotacoes");
  };

  useEffect(() => {
    validateUser();
  }, [token]);

  useEffect(() => {
    LoadBanners();
    LoadCities();
  }, []);

  useEffect(() => {
    LoadQuotes();
    LoadWeatherForecast();
  }, [LoadQuotes, LoadWeatherForecast]);

  return (
    <Box id="homePage">
      <Helmet>
        <title>Peão Digital - Início</title>
      </Helmet>

      <AppBar isLoggedIn={isLoggedIn} />

      <Grid container justifyContent="center" mt={6}>
        <Grid container item lg={10} md={12} sm={12} xs={12} spacing={1} marginTop={{xs:0, lg: 2}}>
          <div className="windmill">
            <div className="hills"></div>
            <div className="wheel">
              <div className="windwheel"></div>
              <div className="windwheel windwheel2"></div>
              <div className="windwheel windwheel3"></div>
              <div className="windwheel windwheel4"></div>
            </div>
            <div className="roof"></div>
            <div className="mill"></div>
            <div className="house"></div>
            <ul className="tree">
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <ul className="bush">
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <ul className="bush bush2">
              <li></li>
              <li></li>
            </ul>
            <div className="cloud"></div>
            <div className="cloud cloud2"></div>
          </div>

          <Grid item lg={6} md={12} sm={12} xs={12} order={{ xs: 1, lg: 1 }}>
            <Card style={{ backgroundColor: weatherCardColor }}>
              <CardContent className="card-content">

                <AutocompleteSelect
                  id="cidade_selecionada"
                  label="Você está em ?"
                  variant="standard"
                  disabled={false}
                  data={dataCities}
                  isMultiple={false}
                  value={selectedCity}
                  onChange={handleCityChange}
                  />

                <Grid container justifyContent="space-between" alignItems="center">

                  <Grid item className="descIcon">
                    <WeatherIcon icon={currentForecast.descricao_original} width={100} />
                  </Grid>

                  <Grid item>
                    <Typography className="temperature" variant="h4">{currentForecast.temperatura_atual} ºC</Typography>
                  </Grid>

                </Grid>

                <Grid container justifyContent="space-between">
                  <Grid className='cardTemp' display="flex" justifyContent="center" alignItems="center">

                    <Grid item display="flex" alignItems="center">
                      <FontAwesomeIcon icon={faArrowUp} style={{ color: "#ff0000" }} />
                      <Typography ml={1} marginRight={1} variant='subtitle1'>{currentForecast.temperatura_max}ºC</Typography>
                    </Grid>

                    <Grid item display="flex" alignItems="center">
                      <FontAwesomeIcon icon={faArrowDown} style={{ color: "#002bd6" }} />
                      <Typography ml={1} variant='subtitle1'>{currentForecast.temperatura_min}ºC</Typography>
                    </Grid>

                  </Grid>

                  <Grid item>
                    <Button className="btnDetails" variant="outlined" onClick={handleForecastDetails}>Previsão Completa</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item lg={12} md={12} sm={12} xs={12} order={{ xs: 3, lg: 3 }} mb={0.5} mt={0.5}>
            <Product product={list_products} isLoggedIn={isLoggedIn} modules={modules} weatherCardColor={weatherCardColor} />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12} order={{ xs: 3, lg: 2 }}>
            <CarouselCards data={dataQuotes}></CarouselCards>
            <Grid container>
              <Grid item xs={12}>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button className="quotesBtn" variant="contained" onClick={handleQuotesDetails}>Cotações Completas</Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        {bannerImg && bannerImg.length > 0 && (
          <Grid container className="section-banner" mt={1}>
          <Grid item lg={10} md={10} sm={11.5} xs={11.5}>
            <CarouselImagem data={bannerImg}></CarouselImagem>
          </Grid>
        </Grid>
        )}
        
        <div id="mascara"></div>
      </Grid>
    </Box>
  );
}