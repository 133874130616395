import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/index.css";

export default function CarouselSlider({ data }) {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const settings = {
    speed: 500,
    dots: true,
    infinite: true,
    lazyLoad: "ondemand",
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    swipe:true,
    touchMove:true,
    useTransform:true,
  };

  return (
    <Slider {...settings}>
      {data.map((item) => (
        <div key={item.id} className="carousel-slide">
          <img src={`${baseURL}${item.arquivo}`} alt={item.nome} className="carousel-image"/>
        </div>
      ))}
    </Slider>
  );
}
