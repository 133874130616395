import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./css/index.css";


import cornIcon from '../../../assets/img/cornIcon.png';
import wheatIcon from '../../../assets/img/wheatIcon.png';
import soybeanIcon from '../../../assets/img/soybeanIcon.png';


export default function CarouselCards({ data }) {

  const settings = {
    dots: false,
    arrows: true,
    mobileFirst: true,
    lazyLoad: 'ondemand',
    infinite: data.length > 1,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
    ],
  };

  const icons = {
    'Aveia': 'oatIcon.png',
    'Café': 'coffeIcon.png',
    'Milho': cornIcon,
    'Trigo': wheatIcon,
    'Feijão': 'beanIcon.png',
    'Soja': soybeanIcon,
    'Algodão': 'cottonIcon.png',
    'Triticale': 'triticaleIcon.png'
  };
  return (
    <Box style={{ width: "100%", maxWidth: "1200px", margin: "0 auto" }}>
      <Slider {...settings}>
        {data.map((item) => (
          <Grid container key={item.id} spacing={0.5}>
            <Grid item xs={12}>
              <Card className="carousel-card">
                <CardContent className="carousel-card-content">
                  <Grid container className="grid-carousel-card"justifyContent="center" spacing={1}>
                    <Grid item xs={12} display="flex" justifyContent="center" mt={2}>
                      <img className="cropIcon" src={icons[item.cultura]} alt={item.cultura}></img>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="caption">
                        {item.cultura}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="h6" className="titles">
                        <strong>R$ {item.valor}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="caption">
                        {item.empresa_nome}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "right" }}>
                      <Typography variant="caption">
                        {item.validade}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ))}
      </Slider>
    </Box>
  );
}
