import { DefaultModel } from "../DefaultModel";
import { searchCompanies, searchPilot, searchQuotesCompanies, searchSubsidiaries } from "../../Services/API/api";

export class Company extends DefaultModel {
  async SearchQuotesCompanies() {
    const response = await searchQuotesCompanies(this.token);
    return (response) || [];
  }

  async SearchCompanies() {
    const response = await searchCompanies(this.token);
    return (response) || [];
  }

  async SearchSubsidiaries(companyId, parameters) {
    const response = await searchSubsidiaries(this.token, companyId, this.converter_obj_query_string(parameters));
    return (response) || [];
  }

  async SearchPilot(companyId, pilotStatus) {
    const response = await searchPilot(this.token, companyId, pilotStatus);
    return (response) || [];
  }

}