import { DefaultModel } from "../DefaultModel";
import { searchMarketQuotes, searchInitialMarketQuotes} from "../../Services/API/api";

export class MarketQuotes extends DefaultModel {
  async SearchInitialMarketQuotes(parameters) {
    const response = await searchInitialMarketQuotes(this.token, this.converter_obj_query_string(parameters));
    return (response) || [];
  }

  async SearchMarketQuotes(parameters) {
    const response = await searchMarketQuotes(this.token, this.converter_obj_query_string(parameters));
    return (response) || [];
  }
}