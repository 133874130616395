import {DefaultModel} from "../DefaultModel";
import { searchApplicationType, searchCulture } from "../../Services/API/api";

export class Culture extends DefaultModel {

	async SearchCulture() {
		const response = await searchCulture();
		return (response) || [];
	}

  async SearchApplicationType() {
    const response = await searchApplicationType(this.token);
    return (response) || [];
  }
}