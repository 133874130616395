import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { faChevronDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { User } from "../../Models/User";
import { City } from '../../Models/Essential/CEP';
import { Culture } from '../../Models/Essential/Culture';
import { MarketQuotes } from '../../Models/Quotes/Quotes';
import { Company } from "../../Models/Organization/Company";

import { getCurrentDate } from "../../Services/globalFunction";

import AutocompleteSelect from '../../Components/Selects/AutocompleteSelect';
import Alertavel from "../../Components/Alertavel";
import Tables from '../../Components/Table';
import AppBar from "../../Components/AppBar";


import cornIcon from '../../assets/img/cornIcon.png';
import wheatIcon from '../../assets/img/wheatIcon.png';
import soybeanIcon from '../../assets/img/soybeanIcon.png';

import "../../App.css";

export default function Quotes() {
  const [title] = useState('Cotações');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dataMarketQuotes, setDataMarketQuotes] = useState([]);
  const [quotes, setQuotes] = useState([]);

  const [dataCities, setDataCities] = useState([]);
  const [dataCulture, setDataCulture] = useState([]);
  const [dataCompanies, setDataCompanies] = useState([]);

  const [dataIni, setDataIni] = useState(getCurrentDate(0, 30, 'subtrai'));
  const [dataEnd, setDataEnd] = useState(getCurrentDate(0, 30, 'soma'));

  const [selectedCity, setSelectedCity] = useState();
  const [selectedCulture, setSelectedCulture] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);

  const [contentAlert, setContentAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const navigate = useNavigate();

  const validateUser = useCallback(() => {
    const user = User.searchUser();
    if (user.isLoggedIn()) {
      setIsLoggedIn(true);
    }
  }, []);

  const LoadMarketQuotes = useCallback(async () => {
    const culturas = selectedCulture.map((val) => val.id);
    const empresas = selectedCompany.map((val) => val.id);

    const parameters = {
      data_inicial: dataIni,
      cidade: selectedCity,
      data_final: dataEnd,
      culturas: culturas,
      empresas: empresas,
    };

    const marketquotes = new MarketQuotes();
    await marketquotes.SearchMarketQuotes(parameters).then((response) => {
      if (response.length > 0) {
        return setDataMarketQuotes(response);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar as cotações. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });

  }, [selectedCompany, selectedCulture, dataIni, selectedCity, dataEnd]);
  
  const LoadCulture = useCallback(async () => {
    const culture = new Culture();
    await culture.SearchCulture().then((response) => {
      if (response.length > 0) {
        const newdataCulture = [{ id: 0, nome: 'Todos' }, ...response];
        setDataCulture(newdataCulture);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar as culturas. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  }, []);

  const LoadCities = useCallback(async () => {
    const city = new City();
    await city.SearchCities('RS').then((response) => {
      if (response.length > 0) {
        return setDataCities(response);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar as cidades. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  }, []);

  const LoadCompanies = useCallback(async () => {
    const company = new Company();
    await company.SearchQuotesCompanies().then((response) => {
      if (response.length > 0) {
        const newdataCompanies = [{ id: 0, nome: 'Todos' }, ...response];
        return setDataCompanies(newdataCompanies);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar as empresas. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  }, []);

  const handleCityChange = async (event) => {
    if (event) {
      const user = User.searchUser();
      user.saveCity(event.id);
      setSelectedCity(event.id);
    }
  };

  const handleCultureChange = (event) => {
    if (event) {
      const isAllSelected = event.some((culture) => culture.id === 0);
  
      if (isAllSelected) {
        const cultureWithoutTodos = event.filter((culture) => culture.id === 0);
        setSelectedCulture(cultureWithoutTodos);
      } else {
        const updatedCultures = event.map((val) => ({ id: val.id, nome: val.nome }));
        setSelectedCulture(updatedCultures);
      }
    } else {
      setSelectedCulture([]);
    }
  };
  
  const handleCompanyChange = (event) => {
    if (event) {
      const isAllSelected = event.some((company) => company.id === 0);
  
      if (isAllSelected) {
        const companiesWithoutTodos = event.filter((machinery) => machinery.id === 0);
        setSelectedCompany(companiesWithoutTodos);
      } else {
        const updatedCompanies = event.map((val) => ({ id: val.id, nome: val.nome }));
        setSelectedCompany(updatedCompanies);
      }
    } else {
      setSelectedCompany([]);
    }
  };

  const handleClosePage = () => {
    navigate("/");
  };

  const filterClick = async () => {
    setOpenAlert(false);
    if(selectedCompany.length > 0 && selectedCulture.length > 0 && selectedCity && dataIni && dataEnd){
      await LoadMarketQuotes();
    } else {
      setContentAlert("É necessário informar todos os dados para fazer a filtragem");
      setOpenAlert(true);
    };
  };  

  useEffect(() => {

    const validateMarketQuotes = () => {

      const icons = {
        'Aveia': 'oatIcon.png',
        'Café': 'coffeIcon.png',
        'Milho': cornIcon,
        'Trigo': wheatIcon,
        'Feijão': 'beanIcon.png',
        'Soja': soybeanIcon,
        'Algodão': 'cottonIcon.png',
        'Triticale': 'triticaleIcon.png'
      };

      const marketQuote = dataMarketQuotes.map((data) => {
        const icon = icons[data.cultura];
      
        return {
          id: data.id,
          cultura: (
            <>
              <img
                src={icon}
                alt={data.cultura}
                style={{ width: 24, height: 24, marginRight: 8 }}
              />
              {data.cultura}
            </>
          ),
          empresa: data.empresa_nome,
          cidade: data.cidades[0]?.cidade_nome,
          valor: `R$ ${parseFloat(data.valor || 0).toFixed(2)}`,
          validade: data.validade,
        };
      });

      setQuotes(marketQuote);
    };

    validateMarketQuotes();
  }, [dataMarketQuotes]);

  useEffect(() => {
    validateUser();
    LoadCities();
    LoadCulture();
    LoadCompanies();
  }, [validateUser, LoadCities, LoadCulture, LoadCompanies]);

  const headers = ["Cultura", "Empresa", "Cidade", "Valor", "Validade"];

  return (
    <Box id="homePage">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <AppBar isLoggedIn={isLoggedIn} />

      <Grid container justifyContent="center" mt={8}>
        <Grid item lg={12} md={12} sm={12} xs={12} mt={2} textAlign="center">
          <Typography className="titles-pages" variant="h5">COTAÇÕES</Typography>
        </Grid>

        <Grid item lg={10} md={12} sm={12} xs={12}>
          <Grid item lg={2} md={12} sm={12} xs={12} m={2}>
            <Button className="BackButton" fullWidth onClick={handleClosePage}>Voltar</Button>
          </Grid>

          <Grid mb={2}>
            <Accordion defaultExpanded id="panel-header" aria-controls="panel-content">
              <AccordionSummary expandIcon={<FontAwesomeIcon className="font-icons-list" icon={faChevronDown} />}>
                <Grid container>
                  <Typography className="titles" variant="h6">Selecione os dados abaixo:</Typography>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container spacing={0.5}>
                  <Grid item lg={2} md={4} sm={4} xs={12}>
                    <TextField
                      type="date"
                      id="data_inicial"
                      value={dataIni}
                      label="Data Inicial"
                      error={!dataIni}
                      onChange={(event) => setDataIni(event.target.value)}
                      InputLabelProps={{ shrink: true, required: true }}
                    />
                  </Grid>

                  <Grid item lg={2} md={4} sm={4} xs={12}>
                    <TextField
                      type="date"
                      id="data_final"
                      value={dataEnd}
                      label="Data Final"
                      error={!dataEnd}
                      onChange={(event) => setDataEnd(event.target.value)}
                      InputLabelProps={{ shrink: true, required: true }}
                    />
                  </Grid>
                  
                  <Grid item lg={2} md={4} sm={12} xs={12}>
                    <AutocompleteSelect
                      id="city"
                      label="Cidade"
                      disabled={false}
                      data={dataCities}
                      isMultiple={false}
                      required={true}
                      value={selectedCity}
                      onChange={handleCityChange}
                    />
                  </Grid>

                  <Grid item lg={2} md={4} sm={12} xs={12}>
                    <AutocompleteSelect
                      id="cultura"
                      label="Cultura"
                      disabled={false}
                      data={dataCulture}
                      isMultiple={true}
                      required={true}
                      value={selectedCulture}
                      onChange={handleCultureChange}
                    />
                  </Grid>

                  <Grid item lg={2} md={4} sm={12} xs={12}>
                    <AutocompleteSelect
                      id="company"
                      label="Empresa"
                      disabled={false}
                      data={dataCompanies}
                      isMultiple={true}
                      required={true}
                      value={selectedCompany}
                      onChange={handleCompanyChange}
                    />
                  </Grid>

                  <Grid className="grid-filter-button" item lg={2} md={2} sm={6} xs={12}>
                    <Tooltip title="Filtrar">
                      <Button className="btnfilter successBtn" fullWidth variant="contained" onClick={filterClick}><FontAwesomeIcon icon={faFilter} /> Filtrar</Button>
                    </Tooltip>
                  </Grid>

                  <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Card>
            <CardContent>
              <Grid container>
                <Tables headers={headers} rows={quotes} actions={null} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}