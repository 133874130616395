import React, { useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Box, TablePagination, Button } from '@mui/material';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';

export default function Tables({ headers, rows, actions }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const tableRef = useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const exportToPNG = async () => {
    if (!tableRef.current || rows.length === 0) return;
  
    const clonedTable = tableRef.current.cloneNode(true);
  
    const headerCells = clonedTable.querySelectorAll('thead th');
    let actionColumnIndex = -1;
  
    headerCells.forEach((cell, index) => {
      if (cell.textContent.trim() === 'Ações') {
        actionColumnIndex = index;
        cell.remove();
      }
    });
  
    if (actionColumnIndex !== -1) {
      const rows = clonedTable.querySelectorAll('tbody tr');
      rows.forEach((row) => {
        const cells = row.querySelectorAll('td');
        if (cells[actionColumnIndex]) {
          cells[actionColumnIndex].remove();
        }
      });
    }
  
    clonedTable.style.overflow = 'visible';
    document.body.appendChild(clonedTable);
  
    try {
      const canvas = await html2canvas(clonedTable, {
        scale: 3,
        useCORS: true,
      });
  
      canvas.toBlob((blob) => saveAs(blob, 'table-export.png'));
    } catch (error) {
      console.error('Erro ao exportar tabela:', error);
    } finally {
      document.body.removeChild(clonedTable);
    }
  };

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" color="primary" onClick={exportToPNG}>
          Exportar PNG
        </Button>
      </Box>
      <TableContainer sx={{ maxHeight: 450 }} ref={tableRef}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((val, index) => (
                <TableCell key={index} sx={{ fontWeight: 'bold' }}>{val}</TableCell>
              ))}
              {actions !== null ? <TableCell sx={{ fontWeight: 'bold' }}>Ações</TableCell> : ''}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={row.id}>
                {Object.entries(row).map(([key, value], index) => {
                  if (key !== 'id' && key !== 'status') {
                    return <TableCell key={index}>{value}</TableCell>;
                  }
                  return null;
                })}
                {actions !== null ? <TableCell key={row.id + index}>{actions(row.id, row.status)}</TableCell> : ''}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box >
  );
}
